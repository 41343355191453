import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import MUITextField from '@mui/material/TextField';
import { GridCell } from '../Grid';
import SearchField from '../SearchField';
import Chip from '../Chip';
import Skeleton from '../SkeletonLoader/Skeleton';
import Checkbox from '../Checkbox';
import coalesceWithEmptyString from '../lib/coalesceWithEmptyString';

const FullWidthSearchField = styled(SearchField)`
  width: 100%;
`;

const ContainerScrollable = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 42px;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;

  @media (min-width: 1024px) {

    flex-wrap: wrap;
    justify-content: center;
    overflow-x: visible;
  }
`;

const StyledChip = styled(Chip)`
  font-weight: 500;
  font-size: .85rem;
`;

const searchPropertyTypes = {
  keywords: PropTypes.string,
  searchFieldTitle: PropTypes.string.isRequired,
  onSearchTrailingIconSelect: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  locationSearch: PropTypes.string,
  locationSearchFieldTitle: PropTypes.string.isRequired,
  onLocationSearchTrailingIconSelect: PropTypes.func.isRequired,
  onLocationSearchChange: PropTypes.func.isRequired,
  categoryParam: PropTypes.string.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  categoryFieldTitle: PropTypes.string.isRequired,
  categoryOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  cities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
      onClick: PropTypes.func.isRequired,
    }).isRequired,
  ),
  showLoader: PropTypes.bool,
  handleLocaleFilterChange: PropTypes.func,
  showJobsForAllLocales: PropTypes.bool,
  checkboxLabel: PropTypes.string,
};

type SearchProperties = InferProps<
typeof searchPropertyTypes
> & {
  showJobsForAllLocales?: boolean;
};

const SearchSkeleton = () => (
  <Skeleton variant="rectangular" width="100%">
    <MUITextField />
  </Skeleton>
);

const Search = ({
  keywords,
  searchFieldTitle,
  onSearchTrailingIconSelect,
  onSearchChange,
  locationSearch,
  locationSearchFieldTitle,
  onLocationSearchTrailingIconSelect,
  onLocationSearchChange,
  categoryParam,
  onCategoryChange,
  categoryFieldTitle,
  categoryOptions,
  cities,
  showLoader,
  handleLocaleFilterChange,
  showJobsForAllLocales,
  checkboxLabel,
}: SearchProperties) => (
  <>
    <GridCell sm={12} lg={4} padding="0 12px 16px">
      {showLoader ? <SearchSkeleton /> : (
        <FullWidthSearchField
          id="jobs-search-text"
          name="search"
          label={searchFieldTitle}
          value={coalesceWithEmptyString(keywords)}
          onChange={onSearchChange}
          onClear={onSearchTrailingIconSelect}
        />
      )}
    </GridCell>
    <GridCell sm={12} lg={4} padding="0 12px 16px">
      {showLoader ? <SearchSkeleton /> : (
        <FullWidthSearchField
          id="jobs-search-location"
          label={locationSearchFieldTitle}
          value={coalesceWithEmptyString(locationSearch)}
          onChange={onLocationSearchChange}
          onClear={onLocationSearchTrailingIconSelect}
        />
      )}
    </GridCell>
    <GridCell sm={12} lg={4} padding="0 12px 16px">
      {showLoader ? <SearchSkeleton /> : (
        <MUITextField
          id="jobs-category-select"
          select
          label={categoryFieldTitle}
          onChange={onCategoryChange}
          placeholder={categoryFieldTitle}
          value={categoryParam}
          fullWidth
          SelectProps={{
            native: true,
          }}
        >
          {categoryOptions.map(
            ({ value, label }) => (
              <option
                value={value}
                key={value}
              >
                {label}
              </option>
            ),
          )}
        </MUITextField>
      )}
    </GridCell>
    {handleLocaleFilterChange && checkboxLabel && (
      <GridCell sm={12} padding="0 0 12px">
        <Checkbox
          checked={showJobsForAllLocales}
          label={checkboxLabel}
          onChange={handleLocaleFilterChange}
        />
      </GridCell>
    )}
    {
      cities?.[0] ? (
        <GridCell sm={12} lg={12} padding="0 12px 16px">
          <ContainerScrollable>
            {cities.map(({
              id,
              name,
              onClick,
              selected,
            }) => (
              <StyledChip
                key={id}
                onClick={onClick}
                label={name}
                color={selected ? 'primary' : 'default'}
              />
            ))}
          </ContainerScrollable>
        </GridCell>
      ) : (showLoader ? (
        <Skeleton variant="rectangular" width="100%">
          <StyledChip />
        </Skeleton>
      ) : <GridCell sm={12} lg={12} padding="0 12px 16px" />)
    }
  </>
);

Search.propTypes = searchPropertyTypes;

export default Search;
